import { loginUser } from '@apis/Auth';
import { Input } from '@components';
import { useUserContext } from '@context/UserContent';
import { AuthWrapper } from '@flow/index';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import URL from '@helpers/url-constant';
import Link from 'next/link';
import loginStyle from '../../styles/login.module.scss';
import getClientConfigByName from '../../helpers/theme-values-provider';

const TOTAL_FIELD = 2; // how many required field
function Login() {
  const router = useRouter();
  const { clientName = 'payment-loyalty' } = router.query;
  const clientConfig = getClientConfigByName(clientName.replace(/-/g, '').toLowerCase());

  const { handleUser } = useUserContext();

  const [dataObj, handleDataObj] = useState({ email: '', password: '' });
  const [isFormPristine, handleFormPristine] = useState(true);
  const [loading, handleLoading] = useState(false);

  // for form submission
  const [hasError, handleHasError] = useState({});
  const [fieldCount, handleFieldCount] = useState(0);
  const [canSubmit, handleCanSubmit] = useState(false);

  const [loginStatus, handleLoginStatus] = useState({
    status: true,
    message: 'Something went wrong',
  });

  useEffect(() => {
    async function signIn() {
      handleLoading(true);

      const res = await loginUser(dataObj);
      if (res.status) {
        const { data } = res;
        data.clientName = clientName;
        handleUser(data);
        router.replace(URL.DASHBOARD);
      } else {
        handleLoginStatus({ status: res.status, message: res.message });
      }

      handleCanSubmit(false);
      handleLoading(false);
    }

    if (canSubmit && fieldCount >= TOTAL_FIELD && !Object.keys(hasError).length) {
      signIn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canSubmit, fieldCount, hasError]);

  function updateField(name, value) {
    if (!loginStatus.status) {
      handleLoginStatus({ status: true, message: 'Something went wrong' });
    }
    handleCanSubmit(false);
    handleDataObj(state => ({ ...state, [name]: value }));
  }

  function handleError({ name, value }) {
    const errors = { ...hasError };

    if (!isFormPristine) {
      handleFieldCount(state => (state === TOTAL_FIELD ? TOTAL_FIELD : state + 1));
    }
    if (value) {
      errors[name] = 'error';
    } else {
      delete errors[name];
    }

    handleHasError(errors);
  }

  function validateForm(e) {
    e.preventDefault();

    if (isFormPristine) {
      handleFormPristine(false);
    }

    handleCanSubmit(true);
  }

  const { status, message } = loginStatus;

  return (
    <AuthWrapper
      pageTitle="Login"
      msg="Hello!"
      showLoading={loading}
      theme={{
        brandLogo: clientConfig.logo,
        bgColor: clientConfig.background,
        headerColor: clientConfig.onBackground,
        welcomeImg: clientConfig.welcomeImg,
        welcomeImgWebp: clientConfig.welcomeImgWebp,
      }}
    >
      {!status && (
        <div className="alert alert-danger" role="alert">
          {message}
        </div>
      )}
      <div className={loginStyle.loginFormContainer}>
        <form onSubmit={validateForm}>
          <Input
            type="email"
            name="email"
            placeholder="Email Address"
            validations={[
              {
                validation: /(.*[A-Za-z0-9]){1}/i,
                message: 'Please enter email address',
              },
              {
                validation:
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: 'Invalid Email',
              },
            ]}
            pristine={isFormPristine}
            onChange={value => updateField('email', value)}
            onError={errorValue => handleError(errorValue)}
            customStyle={{ color: clientConfig.primary }}
          />
          <Input
            type="password"
            name="password"
            placeholder="Password"
            validations={[
              {
                validation: /^.{1,}/i,
                message: 'Please enter password',
              },
              {
                validation: /^.{8,}/i,
                message: 'Please enter a valid password it must contains 8 characters',
              },
            ]}
            pristine={isFormPristine}
            onChange={value => updateField('password', value)}
            onError={errorValue => handleError(errorValue)}
            customStyle={{ color: clientConfig.primary }}
          />
          <button
            type="submit"
            className={loginStyle.loginButton}
            style={{
              backgroundColor: clientConfig.primaryButtonColor,
              color: clientConfig.onPrimaryButton,
            }}
          >
            {loading ? 'Loading...' : 'Login'}
          </button>
        </form>
        <div className={loginStyle.linksContainer}>
          <Link href={URL.FORGOT_PASSWORD}>Forgotten password?</Link>

          <Link href={`/${clientName}/register`}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className={loginStyle.registerButton}>New user? Register here</a>
          </Link>
        </div>
      </div>
    </AuthWrapper>
  );
}

export default Login;
